
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form, Field, ErrorMessage } from "vee-validate";
import Address from "@/components/reusable/Address.vue";
import ClaimNatures from "@/views/reusable/claims/motor-insurance/claim-natures.json";
import ImagesUploader from "@/components/reusable/ImagesUploader.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import * as Yup from "yup";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "report-motor-insurance-claim",
  components: { Form, Field, ErrorMessage, ImagesUploader, Address },
  data() {
    return {
      icClass: "col-xl-12",
      ttgClass: "col-xl-4",
      hprClass: "col-xl-4",
      fbClass: "col-xl-6",
      hasFireBrigadeReport: false,
      hasPoliceReport: false,
      hasBeenTakenToGarage: false,
      hasLicence: false,
      hasThirdPartyInfo: false,
      hasGarageInfo: false,
      isALicensedDriver: false,
      // publicId: this.$route.params.publicId,
      claimNatures: ClaimNatures,
      claimNature: "",
      itemPublicId: "",
      lossDate: "",
      minimumDate: "",
      todaysDate: "",
      locationOfIncident: "",
      policeReport: "",
      takenToGarage: "",
      licensedDriver: "",
      weatherCondition: "",
      accidentImage: "",
      descriptionOfIncident: "",
      thirdPartyFullName: "",
      thirdPartyPhoneNumber: "",
      thirdPartyEyeWitness: "",
      thirdPartyDriverLicense: "",
      thirdPartyInsuranceCertificate: "",
      thirdPartyOtherDocuments: "",
      acceptTerms: false,
      garageName: "",
      garagePhoneNumber: "",
      amount: "",
      amountImage: "",
      garageAddress: "",
      damagedVehicleImages: "",
      policy: "",
      policies: [],
      schedules: [],
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const claimsValidator = Yup.object().shape({
      policy: Yup.mixed()
        .required()
        .label("Policy reference"),
      itemPublicId: Yup.mixed()
        .required()
        .label("Accidented vehicle"),
      claimNature: Yup.mixed()
        .required()
        .label("Claim nature"),
      lossDate: Yup.date()
        .required()
        .label("Date and time of incident"),
      locationOfIncident: Yup.string()
        .required()
        .label("Location of incident"),
      descriptionOfIncident: Yup.string()
        .required()
        .label("Full description of incident"),
      // damagedVehicleImages: Yup.mixed()
      //   .required()
      //   .label("Upload damaged vehicles"),
      // garagePhoneNumber: Yup.number()
      //   .min(11)
      //   .required()
      //   .label("Garage phone number"),
      acceptTerms: Yup.boolean()
        .oneOf([true])
        .required()
        .label("Terms & conditions"),
    });

    const submitForm = (values, { resetForm }) => {
      // console.log(values);
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            const formData = new FormData();

            for (const [key, value] of Object.entries(values)) {
              // if (key === "damagedVehicleImages" && Array.isArray(value)) {
              if (Array.isArray(value)) {
                value.forEach((itemValue, index) => {
                  formData.append(
                    `${key}[${index}]`,
                    itemValue as string | Blob
                  );
                });
              } else {
                formData.append(key, value as string | Blob);
              }
            }

            // return new Response(formData).text().then(console.log);

            ApiService.setHeader();
            ApiService.post(variables.CLAIMS_ROUTE, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                resetForm();
                // Redirect back to claims history page
                router.push({
                  name: "cooperative-admin-members-claims-listing",
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      onMounted(() => {
        setCurrentPageBreadcrumbs("Motor Insurance", [
          "Cooperative",
          "Members",
          "Claims",
          "Report Claims",
        ]);
      });
    });

    return { submitButton1, claimsValidator, submitForm };
  },
  created() {
    // Get the current date
    this.getCurrentDate();

    // Get the list of policies associated with this claim type
    this.getPolicies();

    //Set page title
    document.title =
      "Cooperatve: Member Motor Insurance Claim | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getPolicies() {
      //Fetch client policies
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.COOPERATIVE_UMRELLA_POLCIES_ROUTE}/MOT`)
          .then(({ data }) => {
            //Assign data to props
            this.policies = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getPolicyInformation(event) {
      this.policies.forEach((item) => {
        if (item["publicId"] === event.target.value) {
          if (item["policies"] == "") {
            return variables.toastAlert(
              "No policies found for this umbrella policy.",
              "error"
            );
          }

          this.schedules = item["policies"];
          this.minimumDate = item["policies"][0]["startDate"];
        }
      });
    },
    setPolicyId(event) {
      this.policy =
        event.target.options[event.target.selectedIndex].dataset.policy;
    },
    getClaimNature(event) {
      this.defaultValues();

      const code =
        event.target.options[event.target.selectedIndex].dataset.code;

      // If the claim nature is accident
      if (code == "AC") {
        this.hasLicence = true;
        this.hasBeenTakenToGarage = true;
      } else if (code == "FD") {
        this.hasBeenTakenToGarage = true;
        this.hasFireBrigadeReport = true;
        this.icClass = "col-xl-12";
        this.ttgClass = "col-xl-6";
        this.fbClass = "col-xl-6";
      } else if (code == "TPI") {
        this.hasPoliceReport = true;
        this.hasThirdPartyInfo = true;
        this.hasLicence = true;
      } else if (code == "ST") {
        this.hasPoliceReport = true;
        this.icClass = "col-xl-12";
        this.hprClass = "col-xl-12";
      } else if (code == "VD") {
        this.hasPoliceReport = true;
        this.hasBeenTakenToGarage = true;
        this.hprClass = "col-xl-6";
        this.ttgClass = "col-xl-6";
      } else {
        this.defaultValues();
      }
    },
    getCurrentDate() {
      const currentYear = new Date().getFullYear();
      this.minimumDate = currentYear + "-01-01";
      this.todaysDate = variables.currentDate();
    },
    getGarageChoice(event) {
      if (event.target.options[event.target.selectedIndex].value == "Yes") {
        this.hasGarageInfo = true;
      } else {
        this.hasGarageInfo = false;
      }
    },
    getLicenseChoice(event) {
      if (event.target.options[event.target.selectedIndex].value == "No") {
        this.isALicensedDriver = true;
      } else {
        this.isALicensedDriver = false;
      }
    },
    defaultValues() {
      this.icClass = "col-xl-12";
      this.ttgClass = "col-xl-4";
      this.hprClass = "col-xl-4";
      this.fbClass = "col-xl-6";

      this.hasPoliceReport = false;
      this.hasBeenTakenToGarage = false;
      this.hasLicence = false;
      this.hasThirdPartyInfo = false;
      this.hasGarageInfo = false;
      this.isALicensedDriver = false;
      this.hasFireBrigadeReport = false;

      this.takenToGarage = "";
      this.licensedDriver = "";
      this.weatherCondition = "";
      this.thirdPartyEyeWitness = "";
    },
    capitalizeWord(value) {
      return variables.capitalizeWord(value);
    },
    addCommaToNumber(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));

      // return variables.addCommaToNumber(amount);
    },
    acceptNumberOnly(amount) {
      return variables.acceptNumberOnly(amount);
    },
  },
});
